<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" overlay-opacity="0.75" persistent max-width="452">
      <v-card relative class="backgroundW py-8">
        <div class="pa-5 red12 rounded-circle  d-block ma-auto" style="width: 68px; height: 68px;">
          <img src="@/assets/img/svg_icons/bin.svg" height="28" alt="erp" />
        </div>
        <v-card-title class="text-h6 mt-3 text-center font-weight-bold">
          <h5 class="d-block error--text ma-auto">
            <span>{{ $t('Are you sure to delete') }}</span>
            <span :class="item ? 'mx-1': ''">{{ item }}</span>
            {{ $t('?') }}
          </h5>
        </v-card-title>

        <!-- <v-col cols="12" sm="12">
          <div
            class="body-2 px-5"
          ><p>{{ $t('Erase it permanently, it will be impossible to restore it again, we will wait 5 seconds When the final scan button is activated') }}</p></div>
        </v-col> -->

        <v-col cols="12" class="mt-3 px-8">
          <v-row justify="center">
            <v-col cols="auto">
              <v-btn @click="closeDialog" :height="52"  :width="170" depressed class="rounded-lg font-weight-bold  black--text" color="white">{{ $t('cancel') }}</v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="error" depressed @click="returnData" :height="52" :width="170" class="rounded-lg font-weight-bold white--text">
                <!-- <v-icon class="mx-1" small left>mdi-trash-can</v-icon> -->
                {{ $t('delete') }}
                <!-- <span v-if="counter !== 0">({{ $t(counter) }})</span> -->
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
  </v-row>
</template>



<style scoped lang="scss">
</style>



<script>
export default {
  name: "DeleteConfirmation",
  props: {
    backValueMethod: { type: Function },
    dialog: { default: false },
    item: { default: "" },

  },
  computed: {
    isRTL() {
      return this.$store.state.Settings.isRTL;
    },
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  data() {
    return {
      counter: 5
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false
      this.backValueMethod(false);
      this.counter = 5
    },
    returnData() {
      this.dialog = false
      this.backValueMethod(true);
      // this.counter = 5
    },
    countDown() {
      setTimeout(() => {
        this.counter = this.counter - 1
        if (this.counter !== 0) {
          this.countDown()
        }
      }, 1000);
    },
  },
  mounted() {
    this.countDown();
  }
};
</script>
