// Main Module Routes:-

import Home from "@/views/Dashboard/HomeModule/Home.vue";

export const HomeModuleRoutes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "home",
    },
  },

];
