// TM Module Routes:-
import HomeModule from "@/views/Dashboard/HomeModule/HomeModule";
import MainModule from "@/views/Dashboard/MainModule/MainModule";
import WebsiteModule from "@/views/Dashboard/WebsiteModule/WebsiteModule";
import OthersModule from "@/views/Dashboard/OthersModule/OthersModule";

// Child
import { HomeModuleRoutes } from "./HomeModuleRoutes";
import {MainModuleRoutes} from "./MainModuleRoutes";
import {WebsiteModuleRoutes} from "./WebsiteModuleRoutes";
import {OthersModuleRoutes} from "./OthersModuleRoutes";


export const DashboardRoutes = [
  {
    path: "/",
    name: "HomeModule",
    component: HomeModule,
    meta: {
      title: "home",
    },
    children: [...HomeModuleRoutes],
  },
  {
    path: "/main",
    name: "MainModule",
    component: MainModule,
    meta: {
      title: "main",
    },
    children: [...MainModuleRoutes],
  },
  {
    path: "/website",
    name: "WebsiteModule",
    component: WebsiteModule,
    meta: {
      title: "website",
    },
    children: [...WebsiteModuleRoutes],
  },
  {
    path: "/others",
    name: "OthersModule",
    component: OthersModule,
    meta: {
      title: "others",
    },
    children: [...OthersModuleRoutes],
  },

];
