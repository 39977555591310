<template>
  <section>
    <v-container fluid>

      <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="!disabled" :deleteMethod="deleteMethod" />

      <!-- Form -->
      <v-form ref="form" v-model="valid" class="pa-3">
        <v-card class="backgroundW shadow pa-5 pt-7">
          <v-row align="center">

            <GenericInput type="text" :value="form.title_ar" @input="form.title_ar = $event" label="عنوان عربى"
              :required="true" :isLoading="false" :cols="[12, 4, 4]" class="mt-1" />

            <GenericInput type="text" :value="form.title_en" @input="form.title_en = $event" label="English title"
              :required="true" :isLoading="false" :cols="[12, 4, 4]" class="mt-1" />

            <GenericInput type="select" :lookups="categories" :value="form.category_id"
              @input="form.category_id = $event" label="category" :multi="false" :required="true" selected_label="name"
              selected_prop="id" :isLoading="false" :cols="[12, 4, 4]">
              <template v-slot:append>
                <v-btn icon class="mt-n1 mx-n2" color="success" @click="quickAddDialog = true">
                  <v-icon>mdi-plus-box</v-icon>
                </v-btn>
              </template>
            </GenericInput>

            <GenericInput type="textarea" :value="form.short_description_ar" @input="form.short_description_ar = $event"
              label="وصف مختصر عربى" :required="true" :isLoading="false" :cols="[12, 6, 6]" class="mt-1" />

            <GenericInput type="textarea" :value="form.short_description_en" @input="form.short_description_en = $event"
              label="short description english" :required="true" :isLoading="false" :cols="[12, 6, 6]" class="mt-1" />

            <GenericInput type="texteditor" :value="form.description_ar" @input="form.description_ar = $event"
              label="الوصف عربى" :required="true" :isLoading="false" :cols="[12, 6, 6]" class="mt-2" />

            <GenericInput type="texteditor" :value="form.description_en" @input="form.description_en = $event"
              label="Details english" :required="true" :isLoading="false" :cols="[12, 6, 6]" class="mt-2" />

            <GenericInput type="file" :value="form.image" @input="form.image = $event" label="image" :required="true"
              :base64="false" :isLoading="false" prependIcon="mdi-camera" :cols="[12, 6, 6]" class="mt-2" />


          </v-row>
        </v-card>

      </v-form>

      <!-- End of Form -->
    </v-container>

    <QuickAddDialog :dialog="quickAddDialog" :closeDialog="() => (quickAddDialog = false)" :setQuickAdd="setNewAdd" />
  </section>
</template>

<style scoped lang="scss">
#userSelections {
  .v-chip.v-size--small {
    display: none !important;
  }
}
</style>

<script>
import QuickAddDialog from './QuickAddDialog.vue';

export default {
  name: "BlogControl",

  data: () => ({
    pageData: {
      screen_code: "02-014",
      url: null,
      controlRoute: "/consulting/blog-control/",
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isEdit: false,
      editIsLoading: false,
    },
    valid: false,
    form: {
      title_ar: null,
      title_en: null,
      category_id: null,
      description_ar: null,
      description_en: null,
      short_description_ar: null,
      short_description_en: null,
      image: null,
    },
    quickAddDialog: false,
    categories: [],

  }),

  computed: {
    disabled() {
      return (
        Object.keys(this.form).some(key => !this.form[key])
      );
    }
  },
  mounted() {
    this.pageMainData()
    this.getData()
  },
  watch: {

  },
  methods: {

    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
      this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
    },
    getData() {
      if (this.$route.params.id) {
        if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
          this.pageData.isEdit = true;
          this.pageData.editIsLoading = true;
          this.pageData.isLoading = true;
          this.$api.GET_METHOD(`blogs/${this.$route.params.id}`).then((response) => {
            this.pageData.editIsLoading = false;
            this.pageData.isLoading = false;
            if (response.check) {
              console.log("response", response);
              this.categories = response.data.categories
              Object.keys(this.form).forEach(key => {
                this.form[key] = response.data.data[key]
              });
              this.$refs.form.validate();
            }
          })
        } else {
          this.$router.push('/')
        }
      } else {
        if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

          this.pageData.isLoading = true;
          this.$api.GET_METHOD(`blogs/create`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.categories = response.data.categories
            }
          })
        } else {
          this.$router.push('/')
        }

      }

    },
    save() {
      if (this.$refs.form.validate()) {
        this.pageData.isLoading = true;
        if (this.pageData.isEdit == true) {
          let requestBody = { ...this.form, _method: 'PUT' };
          // requestBody.image = this.form?.image?.substring(0, 7) == '/upload' ? null : this.form?.image;
          this.$api.POST_METHOD_MULTIPART(`blogs/${this.$route.params.id}`, requestBody).then((response) => {
            this.pageData.isLoading = false;
            if (response.check == true) {
              this.$router.push(this.pageData.url)
            }
          })
        }
        else {
          this.$api.POST_METHOD_MULTIPART(`blogs`, this.form).then((response) => {
            this.pageData.isLoading = false;
            if (response.check == true) {
              this.$router.push(this.pageData.url)
            }
          })
        }
      }
    },
    deleteMethod(status) {
      if (status == true) {
        this.pageData.isLoading = true
        this.$api.POST_METHOD_MULTIPART(`blogs/${this.$route.params.id}`, { _method: 'delete' }).then(() => {
          this.$router.push(this.pageData.url)
          this.pageData.isLoading = false
        })
      }
    },
    setNewAdd(newItem) {
      this.categories.push({ ...newItem });
      },
  },

  components: {
    QuickAddDialog
  },
};
</script>
