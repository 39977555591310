<template>
  <v-dialog v-model="dialog" @click:outside="close" :retain-focus="false" max-width="600">
    <v-form ref="form" v-model="valid">
      <v-card class="shadow-none pa-5 " flat>

        <section v-if="!isLoading">
          <v-row align="center">
            <v-col cols="12">
              <div class="d-flex justify-space-between align-center ">
                <h5 class="subtitle-7"> {{ $t('add') }}</h5>
                <v-btn class="error--text" icon depressed @click="close">
                  <v-icon size="24" color="error"> mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>

            <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="عنوان الفئة عربى"
              :required="true" :isLoading="false" :cols="[12, 6, 6]" class="mt-1" />

            <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event"
              label="title category english" :required="true" :isLoading="false" :cols="[12, 6, 6]" class="mt-1" />

            <!-- Actions buttons -->
            <v-col cols="3" class="">
              <v-btn @click="close" depressed height="35" block color=" "
                class="rounded-lg font-weight-bold  red4--text mx-3" :loading="saveIsLoading">
                {{ $t('back') }}
              </v-btn>
            </v-col>

            <v-col cols="9">
              <v-btn @click="save" depressed height="35" block class="rounded-lg font-weight-bold white--text"
                color="blue6" :loading="saveIsLoading">
                {{ $t('add') }}
              </v-btn>
            </v-col>
          </v-row>
        </section>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>

export default {

  name: "QuickAddDialog",
  props: {
    dialog: { default: false },
    closeDialog: { default: Function },
    setQuickAdd: { default: Function },
  },
  data: () => ({
    valid: false,
    isLoading: false,
    saveIsLoading: false,
    form: {
      name_en: null,
      name_ar: null,
    },
    customer_types: [],
    account_trees: [],
  }),
  components: {},
  mounted() { },
  computed: {

  },
  watch: {},
  methods: {
    close() {
      this.closeDialog()
    },

    save() {
      this.saveIsLoading = true;
      this.$api.POST_METHOD('add_category', this.form).then((response) => {
        this.saveIsLoading = false;
        if (response.check) {
          this.setQuickAdd({ ...response.data })
          this.$refs.form.resetValidation()
          this.$refs.form.reset()
          this.closeDialog()
        }
      })
    },

  },

}
</script>

<style lang="scss" scoped></style>