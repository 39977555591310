// Others Module Routes:-

import JobsEntity from "@/views/Dashboard/OthersModule/Jobs/JobsEntity.vue";
import JobsControl from "@/views/Dashboard/OthersModule/Jobs/JobsControl.vue";
import AppliesEntity from "@/views/Dashboard/OthersModule/Applies/AppliesEntity.vue";
import AppliesControl from "@/views/Dashboard/OthersModule/Applies/AppliesControl.vue";
import DealsControlVue from "../views/Dashboard/OthersModule/Deals/DealsControl.vue";
import DealsEntityVue from "../views/Dashboard/OthersModule/Deals/DealsEntity.vue";



export const OthersModuleRoutes = [
  // Ads
  {
    path: "/others/job",
    name: "JobsEntity",
    component: JobsEntity,
    meta: {
      screen_code: "03-001",
    },
  },
  {
    path: "/others/job-control",
    name: "JobsControl",
    component: JobsControl,
    meta: {
      screen_code: "03-001",
    },
  },
  {
    path: "/others/job-control/:id",
    name: "JobsControlEdit",
    component: JobsControl,
    meta: {
      screen_code: "03-001",
    },
  },
  // Applies
  {
    path: "/others/apply",
    name: "AppliesEntity",
    component: AppliesEntity,
    meta: {
      screen_code: "03-002",
    },
  },
  {
    path: "/others/apply-control",
    name: "AppliesControl",
    component: AppliesControl,
    meta: {
      screen_code: "03-002",
    },
  },
  {
    path: "/others/apply-control/:id",
    name: "AppliesControlEdit",
    component: AppliesControl,
    meta: {
      screen_code: "03-002",
    },
  },
  
  // Deals
  {
    path: "/others/deal",
    name: "DealsEntity",
    component: DealsEntityVue,
    meta: {
      screen_code: "03-003",
    },
  },
  {
    path: "/others/deal-control",
    name: "DealsControl",
    component: DealsControlVue,
    meta: {
      screen_code: "03-003",
    },
  },
  {
    path: "/others/deal-control/:id",
    name: "DealsControlEdit",
    component: DealsControlVue,
    meta: {
      screen_code: "03-003",
    },
  },
  

];
