<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
      <!-- End Of Header -->
      <!-- Form -->
      <v-form ref="form" v-model="valid" class="pa-5">
        <v-card class="backgroundW shadow pa-5 pt-7">

          <label class="px-2 mb-5 d-block h3 font-weight-bold">
            {{ $t('Choose nature background') }}
          </label>

          <v-row justify="space-between">

            <v-col cols="12" class="d-flex">
              <GenericInput type="switch" :value="is_video" @input="is_video = $event" label="video" :required="false"
                :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
              </GenericInput>

              <GenericInput type="switch" :value="is_image" @input="is_image = $event" label="image" :required="false"
                :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
              </GenericInput>
            </v-col>

            <!--Video && GIF -->
            <v-col cols="6" v-if="is_video" :key="videoKey">
              <v-file-input outlined dense :label="$i18n.t('disktop background video')" v-model="form.video" show-size />

              <!-- video -->
              <video v-if="form.video" class="mt-3" width="100%" autoplay muted loop :height="400"
                :src="resolveFileDisplay(form.video)">
              </video>
              <!-- video -->

            </v-col>
            <v-col cols="6" v-if="is_video" :key="gifKey">
              <v-file-input outlined dense :label="$i18n.t('mobile background (GIF)')" v-model="form.gif" show-size>
              </v-file-input>

              <!-- GIF -->
              <v-img v-if="form.gif" @error="handelErrorImg(form.gif)" :key="refresher" height="400" width="100%"
                :src="resolveFileDisplay(form.gif)" class="ma-3" contain />
              <!-- GIF -->

            </v-col>
            <!-- End Video && GIF -->


            <!-- Desktop Image &&  Mobile Image-->
            <v-col cols="6" v-if="is_image">
              <v-file-input outlined dense :label="$i18n.t('Background in computers')" v-model="form.image_desktop"
                show-size>
              </v-file-input>

              <v-img v-if="form.image_desktop" @error="handelErrorImg(form.image_desktop)" :key="imgKey" height="400"
                width="100%" :src="resolveFileDisplay(form.image_desktop)" class="ma-3" contain />
            </v-col>

            <v-col cols="6" v-if="is_image">
              <v-file-input  outlined dense :label="$i18n.t('Background on mobile devices')"
                v-model="form.image_mobile" show-size>
              </v-file-input>

              <v-img v-if="form.image_mobile" @error="handelErrorImg(form.image_mobile)" :key="imgKey" height="400"
                width="100%" :src="resolveFileDisplay(form.image_mobile)" class="ma-3" contain />
            </v-col>
            <!-- Desktop Image &&  Mobile Image-->
          </v-row>
        </v-card>


        <!-- Title && Description -->
        <v-card class="backgroundW shadow pa-5 pt-7 mt-3">

          <label class="px-1 mb-5 d-block h3 font-weight-bold">
            {{ $t('Title and description') }}
          </label>

          <v-row justify="space-between">

            <!-- Title in Arabic -->
            <GenericInput type="text" :value="form.title_ar" @input="form.title_ar = $event" label="العنوان بالعربي"
              :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
            </GenericInput>

            <!-- Title in English -->
            <GenericInput type="text" :value="form.title_en" @input="form.title_en = $event" label="title in English"
              :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
            </GenericInput>

            <!-- Description in Arabic -->
            <GenericInput type="texteditor" :value="form.description_ar" @input="form.description_ar = $event"
              label="تفاصيل بالعربي" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
            </GenericInput>

            <!-- Description in English -->
            <GenericInput type="texteditor" dir="ltr" :value="form.description_en" @input="form.description_en = $event"
              label=".description in english" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
            </GenericInput>
          </v-row>
        </v-card>
        <!-- End Title && Description -->


        <!-- Animation Images -->
        <v-card class="backgroundW shadow pa-5 pt-7 mt-3">

          <label class="px-1 mb-5 d-block h3 font-weight-bold">
            {{ $t('Moving pictures') }}
          </label>

          <v-row justify="space-between">
            <!-- Mobile -->
            <GenericInput type="file" :value="form.mobile_image_ar" @input="form.mobile_image_ar = $event"
              label="صورة الجوال" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />
            <GenericInput type="file" dir="ltr" :value="form.mobile_image_en" @input="form.mobile_image_en = $event"
              label="Mobile Image" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

            <!-- tablet -->
            <GenericInput type="file" :value="form.tablet_image_ar" @input="form.tablet_image_ar = $event"
              label="صورة التابلت" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />
            <GenericInput type="file" dir="ltr" :value="form.tablet_image_en" @input="form.tablet_image_en = $event"
              label="tablet Image" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />

            <!-- laptop -->
            <GenericInput type="file" :value="form.laptop_image_ar" @input="form.laptop_image_ar = $event"
              label="صورة اللابتوب" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />
            <GenericInput type="file" dir="ltr" :value="form.laptop_image_en" @input="form.laptop_image_en = $event"
              label="laptop Image" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]" />
          </v-row>

        </v-card>
        <!-- End Animation Images -->

      </v-form>
      <!-- End of Form -->
    </v-container>
  </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
  name: "HeadSectionEntity",
  data: () => ({
    pageData: {
      screen_code: "02-001",
      url: null,
      controlRoute: "website/head_section",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isEdit: false,
      editIsLoading: false,
    },
    gifKey: 1,
    videoKey: 1,
    imgKey: 1,
    refresher: 1,
    valid: false,
    is_video: null,
    is_image: null,
    form: {
      title_en: null,
      title_ar: null,
      description_en: null,
      description_ar: null,
      laptop_image_en: null,
      laptop_image_ar: null,
      tablet_image_en: null,
      tablet_image_ar: null,
      mobile_image_en: null,
      mobile_image_ar: null,
      video: null,
      gif: null,
      image_desktop: null,
      image_mobile: null,
      background_type: null,
    }
  }),
  components: {
    ControlHeader,
    GenericInput,
  },
  watch: {
    'form.gif'() {
      if (this.form.gif) {
        if (this.form.gif.type !== "image/gif" && typeof this.form.gif !== 'string') {
          this.form.gif = null;
          this.gifKey = this.gifKey +
            this.$api.ShowAlert('info', '', this.$i18n.t('please select a gif'))
        }
      }
    },
    'form.video'() {
      if (this.form.video) {
        if (this.form.video.type !== "video/mp4" && typeof this.form.video !== 'string') {
          this.form.video = null;
          this.videoKey++
          this.$api.ShowAlert('info', '', this.$i18n.t('please select a video'))
        }
      }
    },
    'form.image_desktop'() {
      if (this.form.image_desktop) {
        //validate file
        this.callBackImg(this.form.image_desktop)
      }
    },
    'form.image_mobile'() {
      if (this.form.image_mobile) {
        //validate file
        this.callBackImg(this.form.image_mobile)
      }
    },
    'is_image'() {
      if (this.is_image) {
        this.form.background_type = 2;
        this.videoKey++
        this.is_video = null
        this.form.video = null
        this.form.gif = null
      }
    },
    'is_video'() {
      if (this.is_video) {
        this.form.background_type = 1;
        this.gifKey++
        this.is_image = null
        this.form.image_desktop = null
        this.form.image_mobile = null
      }
    },
    'form.background_type'() {
      if (this.form.background_type == 1) {
        this.is_video = 1
      } else if (this.form.background_type == 2) {
        this.is_image = 1
      }
    }
  },
  computed: {
    resolveFileDisplay() {
      return (file) => {
        if (typeof file === 'object') {
          let url = URL.createObjectURL(file);
          return url;
        }
        // handel files in edit
        else if (typeof file === 'string') {
          return this.$api.serverUrl + file
        }
      };
    },
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
      this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
        this.pageData.isLoading = true;
        this.$api.GET_METHOD(`head_section/1`).then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            response.data.data._method = 'PUT';
            this.form = response.data.data
          }
        })
      } else {
        this.$router.push('/')
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        this.form._method = 'PUT';
        this.pageData.isLoading = true;
        console.log('save', this.form);
        this.$api.POST_METHOD_MULTIPART(`head_section/1`, this.form).then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.getData()
          }
        })
      }
    },
    handelErrorImg(img) {
      console.log('handelErrorImg', img);
      img = require('@/assets/img/svg/Broken-Document.svg')
      this.refresher++
    },
    callBackImg(img) {
      console.log('img', img);
      console.log('this.$global.FILE_TYPE(img)', this.$global.FILE_TYPE(img));
      if (
        this.$global.FILE_TYPE(img) !== 'png' &&
        this.$global.FILE_TYPE(img) !== 'jpg' &&
        this.$global.FILE_TYPE(img) !== 'svg' &&
        this.$global.FILE_TYPE(img) !== 'jpeg'
      ) {
        img = null
        this.imgKey++
        this.$api.ShowAlert('info', '', this.$i18n.t('Please choose a file format (PNG, JPEG, SVG, or JPG)'))
      }
    },
    deleteMethod() {

    },


  },
};
</script>

