// Website Module Routes:-
import HeadSectionEntity from "../views/Dashboard/WebsiteModule/HeadSection/HeadSectionEntity.vue";

import ServicesEntity from "@/views/Dashboard/WebsiteModule/Services/ServicesEntity.vue";
import ServicesControl from "@/views/Dashboard/WebsiteModule/Services/ServicesControl.vue";
import StaticPagesEntity from "@/views/Dashboard/WebsiteModule/StaticPages/StaticPagesEntity.vue";
import StaticPagesControl from "@/views/Dashboard/WebsiteModule/StaticPages/StaticPagesControl.vue";
import SlidersEntity from "@/views/Dashboard/WebsiteModule/Sliders/SlidersEntity.vue";
import SlidersControl from "@/views/Dashboard/WebsiteModule/Sliders/SlidersControl.vue";
import ContactUsEntity from "@/views/Dashboard/WebsiteModule/ContactUs/ContactUsEntity.vue";
import ContactUsControl from "@/views/Dashboard/WebsiteModule/ContactUs/ContactUsControl.vue";
import WebsiteBasicsControl from "@/views/Dashboard/WebsiteModule/WebsiteBasics/WebsiteBasicsControl.vue";
import SectionsEntity from "@/views/Dashboard/WebsiteModule/Sections/SectionsEntity.vue";
import SectionsControl from "@/views/Dashboard/WebsiteModule/Sections/SectionsControl.vue";
import FeatureEntity from "@/views/Dashboard/WebsiteModule/Feature/FeatureEntity.vue";
import FeatureControl from "@/views/Dashboard/WebsiteModule/Feature/FeatureControl.vue";
import DemoRequestEntity from "../views/Dashboard/WebsiteModule/DemoRequest/DemoRequestEntity.vue";
import SEOControl from "../views/Dashboard/WebsiteModule/SEO/SEOControl.vue";
import SEOEntity from "../views/Dashboard/WebsiteModule/SEO/SEOEntity.vue";
import AppSectionEntity from "../views/Dashboard/WebsiteModule/AppSection/AppSectionEntity.vue";
import AppSectionControl from "../views/Dashboard/WebsiteModule/AppSection/AppSectionControl.vue";
import SocialMediaEntity from "../views/Dashboard/WebsiteModule/SocialMedia/SocialMediaEntity.vue";
import SocialMediaControl from "../views/Dashboard/WebsiteModule/SocialMedia/SocialMediaControl.vue";

// Blog
import BlogEntity from "@/views/Dashboard/WebsiteModule/blog/BlogEntity";
import BlogControl from "@/views/Dashboard/WebsiteModule/blog/BlogControl";


export const WebsiteModuleRoutes = [
  // Head Section
  {
    path: "/website/head_section",
    name: "HeadSectionEntity",
    component: HeadSectionEntity,
    meta: {
      screen_code: "02-001",
    },
  },
  // services
  {
    path: "/website/service",
    name: "ServicesEntity",
    component: ServicesEntity,
    meta: {
      screen_code: "02-002",
    },
  },
  {
    path: "/website/service-control",
    name: "ServicesControl",
    component: ServicesControl,
    meta: {
      screen_code: "02-002",
    },
  },
  {
    path: "/website/service-control/:id",
    name: "ServicesControlEdit",
    component: ServicesControl,
    meta: {
      screen_code: "02-002",
    },
  },
  // App Section
  {
    path: "/website/app_section",
    name: "AppSectionEntity",
    component: AppSectionEntity,
    meta: {
      screen_code: "02-012",
    },
  },
  {
    path: "/website/app_section-control",
    name: "AppSectionControl",
    component: AppSectionControl,
    meta: {
      screen_code: "02-012",
    },
  },
  {
    path: "/website/app_section-control/:id",
    name: "AppSectionControlEdit",
    component: AppSectionControl,
    meta: {
      screen_code: "02-012",
    },
  },
  // Static Pages
  {
    path: "/website/static_page",
    name: "StaticPagesEntity",
    component: StaticPagesEntity,
    meta: {
      screen_code: "02-003",
    },
  },
  {
    path: "/website/static_page-control",
    name: "StaticPagesControl",
    component: StaticPagesControl,
    meta: {
      screen_code: "02-003",
    },
  },
  {
    path: "/website/static_page-control/:id",
    name: "StaticPagesControlEdit",
    component: StaticPagesControl,
    meta: {
      screen_code: "02-003",
    },
  },

  // Sliders
  {
    path: "/website/slider",
    name: "SlidersEntity",
    component: SlidersEntity,
    meta: {
      screen_code: "02-004",
    },
  },
  {
    path: "/website/slider-control",
    name: "SlidersControl",
    component: SlidersControl,
    meta: {
      screen_code: "02-004",
    },
  },
  {
    path: "/website/slider-control/:id",
    name: "SlidersControlEdit",
    component: SlidersControl,
    meta: {
      screen_code: "02-004",
    },
  },
  // WebsiteBasics
  {
    path: "/website/website_basic_data",
    name: "WebsiteBasicsControl",
    component: WebsiteBasicsControl,
    meta: {
      screen_code: "02-006",
    },
  },
  // Contact Us
  {
    path: "/website/contact_us",
    name: "ContactUsEntity",
    component: ContactUsEntity,
    meta: {
      screen_code: "02-007",
    },
  },
  {
    path: "/website/contact_us-control",
    name: "ContactUsControl",
    component: ContactUsControl,
    meta: {
      screen_code: "02-007",
    },
  },
  {
    path: "/website/contact_us-control/:id",
    name: "ContactUsControlEdit",
    component: ContactUsControl,
    meta: {
      screen_code: "02-007",
    },
  },
  // Sections
  {
    path: "/website/section",
    name: "SectionsEntity",
    component: SectionsEntity,
    meta: {
      screen_code: "02-008",
    },
  },
  {
    path: "/website/section-control",
    name: "SectionsControl",
    component: SectionsControl,
    meta: {
      screen_code: "02-008",
    },
  },
  {
    path: "/website/section-control/:id",
    name: "SectionsControlEdit",
    component: SectionsControl,
    meta: {
      screen_code: "02-008",
    },
  },
  // Feature
  {
    path: "/website/feature",
    name: "FeatureEntity",
    component: FeatureEntity,
    meta: {
      screen_code: "02-009",
    },
  },
  {
    path: "/website/feature-control",
    name: "FeatureControl",
    component: FeatureControl,
    meta: {
      screen_code: "02-009",
    },
  },
  {
    path: "/website/feature-control/:id",
    name: "FeatureControlEdit",
    component: FeatureControl,
    meta: {
      screen_code: "02-009",
    },
  },
  // Demo request
  {
    path: "/website/demo_request",
    name: "DemoRequestEntity",
    component: DemoRequestEntity,
    meta: {
      screen_code: "02-010",
    },
  },
  {
    path: "/website/seo",
    name: "SEOEntity",
    component: SEOEntity,
    meta: {
      screen_code: "02-011",
    },
  },
  {
    path: "/website/seo-control",
    name: "SEOControl",
    component: SEOControl,
    meta: {
      screen_code: "02-011",
    },
  },
  {
    path: "/website/seo-control/:id",
    name: "SEOControlEdit",
    component: SEOControl,
    meta: {
      screen_code: "02-011",
    },
  },
    // SocialMedia
    {
      path: "/website/social_media",
      name: "SocialMediaEntity",
      component: SocialMediaEntity,
      meta: {
        screen_code: "02-013",
      },
    },
    {
      path: "/website/social_media-control",
      name: "SocialMediaControl",
      component: SocialMediaControl,
      meta: {
        screen_code: "02-013",
      },
    },
    {
      path: "/website/social_media-control/:id",
      name: "SocialMediaControlEdit",
      component: SocialMediaControl,
      meta: {
        screen_code: "02-013",
      },
    },

    
  // Blog------------------------------------------------
  {
    path: "/website/blog",
    name: "BlogEntity",
    component: BlogEntity,
    meta: {
      screen_code: "02-014",
    },
  },
  {
    path: "/website/blog-control",
    name: "BlogControlVue",
    component: BlogControl,
    meta: {
      screen_code: "02-014",
    },
  },
  {
    path: "/website/blog-control/:id",
    name: "BlogControlEdit",
    component: BlogControl,
    meta: {
      screen_code: "02-014",
    },
  },
];
