<template>
  <div>
    <v-main v-if="$store.state.splashScreen == false">
      <v-container fluid>
        <v-row>
          <v-expand-x-transition>
            <v-navigation-drawer class="shadow-none border-0" style="z-index : 66" :right="$vuetify.rtl == true"
              mini-variant-width="80" :mini-variant.sync="$store.state.sidebarIsOpen" :mobile-breakpoint="991"
              v-if="!$store.state.topAppBar" app>
              <Sidebar></Sidebar>
            </v-navigation-drawer>
          </v-expand-x-transition>

          <Appbar />

          <v-container fluid class="py-1">
            <router-view></router-view>
          </v-container>
        </v-row>
      </v-container>
    </v-main>
  </div>

</template>


<script>
import Appbar from "@/components/ui/Appbar.vue";
import Sidebar from "@/components/ui/Sidebar.vue";
export default {
  name: "Dashboard",
  components: {
    Appbar,
    Sidebar,
  },
  mounted() {
    this.darkTheme = this.$store.state.Settings.darkTheme;
    setInterval(() => {
      this.networkConnection = this.$api.CHECK_INTERNET();
      this.$store.state.networkConnection = this.$api.CHECK_INTERNET();
    }, 2000);
  },
  data() {
    return {
      mini: false,
      darkTheme: false,
      networkConnection: Boolean
    }
  },
  methods: {
    darkThemee() {
      this.$store.dispatch("Settings/darkTheme");
    },
  },

}
</script>
<style scoped>
</style>