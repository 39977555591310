import Vue from "vue";
import Vuex from "vuex";
import Settings from "./Settings";
import router from "../router";
import i18n from "../plugins/i18n";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // APP Settings
    app_version: process.env.VUE_APP_VERSION, //last app update Year.Month.Day.UploadNumber
    splashScreen: false,
    offline: false,
    sidebarIsOpen: false,
    topAppBar: false,
    // endpointURL: "https://web-api.qaraterp.com/", // Live
    endpointURL: "https://qarat-web-api.cs-testing.com/",//  Testing
    apiAccess:
      "xcVBybDQhtDRqPxFUkpe1JVsZqyGGBR1v1KqendQ2qcLbqXB9hgqin41uRJ3qGTwD0L1hmRS29a0gXbKf8EBVcBy7ofvhKviDzJ88u8p6Hs9mNncXZSXOxeSQ7Ka7tlEhCnBMFtVSgZbuYf9sBubou5pWBxu1okFMC2ksCfk3jxdTQmeHq5xBlOL3pmkqq32L26LX1EB",
    // route settings
    route: null,
    pageTitle: null,
    activeScreenCode: null,
    prevScreen: null,
    activeScreen: null,
    entityName: null,
    entityRoute: null,
    controlRoute: null,
    // user data
    clientID: "qarat_web",
    lastUpdate: localStorage.getItem("lastUpdate")
      ? localStorage.getItem("lastUpdate")
      : " ",
    accessToken: null,
    isAuth: false,
    screens: [],
    permissions: [],
    shift: {},
    current_branch: "",
    branches: [],
    current_year: "",
    years: [],
    shiftOpen: false,
    userData: {},
  },
  mutations: {
    SET_ROUTE(state, route) {
      state.route = route;
      state.activeScreenCode = route.meta.screen_code;
      state.entityName = route.meta.entity_name || null;
      state.entityRoute = route.meta.entity_path || null;
      state.controlRoute = route.meta.control_route || null;
      state.pageTitle = route.meta.title || null;
    },
    UPDATE_ACTIVE_SCREEN(state, activeScreen) {
      state.activeScreen = activeScreen;
      state.pageTitle = activeScreen.sub_title || null;
    },
    UPDATE_CLIENT(state, id) {
      state.clientID = id;
      axios.defaults.headers.common["clientID"] = id;
    },
  },
  getters: {
    activeScreen(state) {
      return state.activeScreen;
    },
  },
  actions: {
    checkAppVersion() {
      localStorage.setItem("app_version", process.env.VUE_APP_VERSION);
      // let localStorageVersion = localStorage.getItem("app_version");
      // if (state.app_version != localStorageVersion) {
      //   state.isAuth = false;
      //   router.push("/login");
      //   axios.interceptors.response.use(
      //     (resp) => {
      //       if(resp.config.method == 'get'){
      //         localStorage.setItem("app_version", state.app_version);
      //         // window.location.reload()
      //       }
      //       return Promise.resolve(resp)
      //     },
      //   )
      // }
    },
    async pause(ms = 1) {
      new Promise((resolve) => setTimeout(resolve, ms));
    },
    checkAuth({ state }) {
      state.isAuth = localStorage.getItem(
        Vue.prototype.$global.CRYPT("cloud", "access-token")
      )
        ? true
        : false;
      if (!state.isAuth) {
        router.push("/login").catch(() => {});
      } else {
        state.accessToken = Vue.prototype.$global.DECRYPT(
          "cloud",
          localStorage.getItem(
            Vue.prototype.$global.CRYPT("cloud", "access-token")
          )
        );
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + state.accessToken;
        state.permissions = JSON.parse(
          Vue.prototype.$global.DECRYPT(
            "cloud",
            localStorage.getItem(
              Vue.prototype.$global.CRYPT("cloud", "permissions")
            )
          )
        );
        state.screens = JSON.parse(
          Vue.prototype.$global.DECRYPT(
            "cloud",
            localStorage.getItem(
              Vue.prototype.$global.CRYPT("cloud", "screens")
            )
          )
        );
      }
    },
    getUserData({ state }) {
      state.splashScreen = true;
      let yearId = localStorage.getItem("yearid");
      let branchId = localStorage.getItem("branchid");
      if (yearId) {
        Vue.prototype.$api.SET_Branch_Year(null, yearId, false);
      }
      if (branchId) {
        Vue.prototype.$api.SET_Branch_Year(branchId, null, false);
      }
      Vue.prototype.$api.GET_METHOD(`get_my_profile`).then((response) => {
        state.splashScreen = false;
        if (response.check) {
          Vue.prototype.$api.SET_AUTH(response.data);
        }
      });
    },
    setPagesRows({ state } ,data){
      state.activeScreen.pageNumber = data.pageNumber ;
      state.activeScreen.rowsNumber = data.rowsNumber ;
    },
    checkRoute({ state, commit }) {
      if (state.activeScreenCode && state.isAuth) {
        // state.prevScreen = state.activeScreen;
        var activeScreen = state.permissions.filter(
          (obj) => obj["screen_code"] === state.activeScreenCode
        );
        activeScreen[0].params = router.currentRoute.params,
        console.log("prevScreen", state.prevScreen);
        console.log("activeScreen", activeScreen[0]);
        document.title = i18n.t("qarat - ") + activeScreen[0].sub_title;
        return commit("UPDATE_ACTIVE_SCREEN", activeScreen[0]);
      } else {
        document.title = state.pageTitle
          ? i18n.t("qarat - ") + i18n.t(state.pageTitle)
          : i18n.t("qarat");
          var undefinedScreen = {
            screen_code: null,
            permission: null,
            main_id: null,
            main_title: null,
            cat_id: null,
            cat_title: null,
            sub_id: null,
            sub_title: null,
            url: router.currentRoute.fullPath,
            params: router.currentRoute.params,
          }
          
          console.log("activeScreen", undefinedScreen);
        return commit("UPDATE_ACTIVE_SCREEN", undefinedScreen );
      }
    },
  },

  modules: { Settings },
});
