<template>
  <v-container fluid class="backgroundW shadow-none" id="appBar">
    <v-row justify="space-between" align="center">

      <v-col col="12" lg="8" md="auto" sm="12" class="overflow-hidden" style="height: 58px;">
        <v-fade-transition>
          <v-row align="center" v-if="$store.state.topAppBar">
            <v-col cols="2" class="py-1">
              <router-link to="/">
                <AnimatedLogo height="40px" />
              </router-link>
            </v-col>
            <v-col cols="auto" class="px-0">
              <AppBarRoutesMenu />
            </v-col>
          </v-row>
        </v-fade-transition>

      </v-col>
      <v-col cols="12" md="4" sm="12" class="py-1">
        <v-row align="center" justify="end">
          <v-col cols="3" md="auto" sm="auto">
            <v-btn v-on:click="changeLanguage" x-small fab class="bg-white d-block ma-auto" elevation="0">
              <img src="@/assets/img/svg_icons/USA.svg" height="25" v-if="$vuetify.rtl == true" alt="qarat" />
              <img src="@/assets/img/svg_icons/KSA.svg" height="25" v-if="$vuetify.rtl == false" alt="qarat" />
            </v-btn>
          </v-col>
          <!-- <v-col cols="3" md="auto" sm="auto">
            <v-btn x-small fab elevation="0" v-on:click="dashboardView">
              <v-icon v-if="!$store.state.topAppBar">mdi-view-compact</v-icon>
              <v-icon v-if="$store.state.topAppBar">mdi-view-list</v-icon>
            </v-btn>
          </v-col> -->
          <v-col cols="3" md="auto" sm="auto">
            <v-btn x-small fab elevation="0" v-fullscreen="options">
              <v-icon v-if="!fullscreen">mdi-fullscreen</v-icon>
              <v-icon v-if="fullscreen">mdi-fullscreen-exit</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3" md="auto" sm="auto">
            <v-btn x-small fab @click="darkTheme" depressed>
              <v-icon v-if="$store.state.Settings.darkTheme" color="yellow1">mdi-white-balance-sunny</v-icon>
              <v-icon v-if="!$store.state.Settings.darkTheme">mdi-weather-night</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="pa-0">
            <v-divider vertical style="height: 40px"></v-divider>
          </v-col>
          <v-col cols="3" md="auto" sm="auto">
            <UserMenu></UserMenu>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import UserMenu from "./UserMenu.vue";
import AppBarRoutesMenu from "./AppBarRoutesMenu.vue";
import AnimatedLogo from "./AnimatedLogo.vue";
export default {
  name: "Appbar",
  computed: {
    options() {
      return {
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen;
        },
        target: ".directive-fullscreen-wrapper",
        pageOnly: this.pageOnly,
        teleport: this.teleport,
      };
    },
  },
  data() {
    return {
      fullscreen: false,
    };
  },
  components: {
    UserMenu,
    AnimatedLogo,
    AppBarRoutesMenu
  },
  methods: {
    darkTheme() {
      this.$store.dispatch("Settings/darkTheme");
    },
    toggleSidebar() {
      this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
    dashboardView() {
      localStorage.setItem('appBarNavigation', !this.$store.state.topAppBar)
      this.$store.state.topAppBar = !this.$store.state.topAppBar;
    },
  },
};
</script>

<style lang="scss" scoped>
#appBar {
  position: sticky;
  height: 60px;
  z-index: 55;
  top: 0;
  //width: 100vw !important;
  //left: 0 !important;
  //right: 0 !important;
  overflow: hidden;
  display: flex;
  //box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}
</style>