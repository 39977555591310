<template>
    <router-view appear :key="path"></router-view>
</template>


<script>
export default {
  name: "OthersModule",
  components: {},
  data: () => ({
    path: null
  }),
};
</script>
